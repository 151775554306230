<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-10 sheet rounded-lg mb-10">
          <!-- 选择图片 -->
          <v-file-input
            v-model="files"
            accept="image/png, image/jpeg, image/bmp, image/webp"
            placeholder="选择一张图片"
            prepend-icon="mdi-image"
            label="图片"
            :show-size="1000"
            multiple
            counter
            @click:clear="onClear"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
          <!-- 选择格式 -->
          <v-select
            v-model="currentType"
            :items="items"
            label="导出类型"
            outlined
          ></v-select>
          <!-- 导出按钮 -->
          <v-btn
            :disabled="!files.length"
            class="mb-4"
            color="success"
            block
            @click="exportImage"
          >生成 {{ currentType }}</v-btn>
          <v-progress-linear
            :value="progress"
            class="mb-4"
            color="light-green darken-4"
            height="10"
            striped
          ></v-progress-linear>
          <!-- 画板 -->
          <canvas ref="canvas" class="canvas"></canvas>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { download } from '@/utils'

const TypeMap = {
  PNG: { mimetype: 'image/png', ext: 'png' },
  JPEG: { mimetype: 'image/jpeg', ext: 'jpg' },
  BMP: { mimetype: 'image/bmp', ext: 'bmp' },
  WEBP: { mimetype: 'image/webp', ext: 'webp' }
}

export default {
  data () {
    return {
      files: [],
      // TODO: 用作查看指定图片
      index: 0,
      items: ['PNG', 'JPEG', 'BMP', 'WEBP'],
      currentType: 'WEBP',
      finishCount: 0
    }
  },
  computed: {
    currentTypeInfo () {
      return TypeMap[this.currentType]
    },
    progress () {
      if (this.files.length === 0) return 0
      return this.finishCount / this.files.length * 100
    }
  },
  watch: {
    files () {
      if (!this.files.length) {
        return
      }
      this.reRender(this.index)
    }
  },
  methods: {
    reRender (index, cb) {
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.src = URL.createObjectURL(this.files[index])
      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        URL.revokeObjectURL(img.src)
        typeof cb === 'function' && cb()
      }
    },
    onClear () {
      this.index = 0
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },
    exportImage () {
      const canvas = this.$refs.canvas
      const currentTypeInfo = this.currentTypeInfo
      this.finishCount = 0
      this.files.forEach((file, index) => {
        this.reRender(index, () => {
          const src = canvas.toDataURL(currentTypeInfo.mimetype)
          download(src, {
            name: `${file.name}.${currentTypeInfo.ext}`,
            type: currentTypeInfo.mimetype
          })
          this.finishCount++
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.canvas {
  display: block;
  position: relative;
  max-width: 500px;
  max-height: 800px;
  margin: 0 auto;
}
</style>
